var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[(_vm.loading)?_c('div',{style:(_vm.loadingStyle)}):_vm._e(),(_vm.loading)?_c('v-progress-circular',{style:(_vm.loadingSpinner),attrs:{"indeterminate":""}}):_vm._e(),_c('v-tabs',{attrs:{"color":"deep-purple accent-4","fixed-tabs":""}},[_vm._l((_vm.tabs),function(item,index){return _c('v-tab',{key:index},[_c('span',[_vm._v(_vm._s(item.tab))])])}),_c('v-tab-item',[_c('p',{staticClass:"text-h4 text--primary"},[_vm._v(" "+_vm._s(_vm.tabs[0].title)+" ")]),(_vm.tabs[0].text != null)?_c('editor_ca',{ref:"editor_ca",attrs:{"api-key":"098e2lg9ndghp2biwixep6nvijjvrvey515t8ow4bp0ehsnf","init":{
                  height: 500,
                  width:900,
                  menubar: false,
                  plugins: 'table',
                  inline: true,
                  table_responsive_width: true,
                  table_sizing_mode: 'responsive',
                  table_column_resizing: 'resizetable',
                  table_grid: false,
                  table_use_colgroups: false,
                },"force_br_newlines":true,"force_p_newlines":false,"value":_vm.tabs[0].text}}):_vm._e(),_c('v-btn',{staticClass:"mt-4",attrs:{"type":"submit","color":"primary","value":"Process"},on:{"click":_vm.process}},[_vm._v(" Processar Idiomes ")])],1),_c('v-tab-item',[_c('p',{staticClass:"text-h4 text--primary"},[_vm._v(" "+_vm._s(_vm.tabs[1].title)+" ")]),(_vm.tabs[1].text != null)?_c('editor_es',{ref:"editor_es",attrs:{"api-key":"098e2lg9ndghp2biwixep6nvijjvrvey515t8ow4bp0ehsnf","init":{
                  height: 500,
                  width:900,
                  menubar: false,
                  plugins: 'table',
                  inline: true,
                  table_responsive_width: true,
                  table_sizing_mode: 'responsive',
                  table_column_resizing: 'resizetable',
                  table_grid: false,
                  table_use_colgroups: false,
                },"force_br_newlines":true,"force_p_newlines":false,"value":_vm.tabs[1].text}}):_vm._e(),_c('v-btn',{staticClass:"mt-4",attrs:{"type":"submit","color":"primary","value":"Process"},on:{"click":_vm.process}},[_vm._v(" Processar Idiomes ")])],1),_c('v-tab-item',[_c('p',{staticClass:"text-h4 text--primary"},[_vm._v(" "+_vm._s(_vm.tabs[2].title)+" ")]),(_vm.tabs[2].text != null)?_c('editor_en',{ref:"editor_en",attrs:{"api-key":"098e2lg9ndghp2biwixep6nvijjvrvey515t8ow4bp0ehsnf","init":{
                  height: 500,
                  width:900,
                  menubar: false,
                  plugins: 'table',
                  inline: true,
                  table_responsive_width: true,
                  table_sizing_mode: 'responsive',
                  table_column_resizing: 'resizetable',
                  table_grid: false,
                  table_use_colgroups: false,
                },"force_br_newlines":true,"force_p_newlines":false,"value":_vm.tabs[2].text}}):_vm._e(),_c('v-btn',{staticClass:"mt-4",attrs:{"type":"submit","color":"primary","value":"Process"},on:{"click":_vm.process}},[_vm._v(" Processar Idiomes ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
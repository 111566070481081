<template>
     <v-flex xs12 sm12 md12></v-flex>
</template>
<script>
    export default {
        async created() {
           
            const url = document.cookie.split(';').find(ck => ck.replaceAll(' ','').indexOf('url=') == 0)

            if(url) {
                window.location.href = url.replace('url=','')
            }
        },
    };
</script>
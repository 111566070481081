import Vue from 'vue'
import Router from 'vue-router'
import auth from '../auth/auth';
import Dashboard from '../components/Dashboard.vue'
import Home from '../components/Home.vue'
import UserInfoStore from '../auth/user-info-store';
import UserInfoApi from '../auth/user-info-api';

Vue.use(Router)

function requireAuth(to, _from, next) {
  
  if (!auth.auth.isUserSignedIn()) {
      document.cookie = `url=${window.location.href};path=/`
      UserInfoStore.setLoggedIn(false);
      next({
      path: '/login',
      query: { redirect: to.fullPath }
      });
  } else {
    UserInfoApi.getUserInfo().then(response => {
      UserInfoStore.setLoggedIn(true);
      UserInfoStore.setCognitoInfo(response);
      next();
    });
      
  }
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/dashboard/:media/:id',
      name: 'Dashboard',
      component: Dashboard,
      beforeEnter: requireAuth
    },
    {
      path: '/login', beforeEnter(){
        auth.auth.getSession();
      }
    },
    {
      path: '/login/oauth2/code/cognito', beforeEnter(){
        var currUrl = window.location.href;

        auth.auth.parseCognitoWebResponse(currUrl);
        //next();
      }
    },
  ]
})
<template>
  <v-app id="inspire" fluid class="grey lighten-5">

    <template>
      <v-app-bar dense clipped-left color="#0A4A6D" app :extended="extended" dark>


        <v-toolbar-title>
          Watchity Traduccions
        </v-toolbar-title>

 
      </v-app-bar>



      <v-main>
        <v-container>
          <router-view></router-view>
        </v-container>
      </v-main>
    </template>
  </v-app>
</template>

<script>

export default {
  name: "App",
  computed: {
  },
  data() {
    return {
      dialog: false,
      sessions: null,
      extended: null,
      drawer: true,
      items: [
        { title: "Translates", icon: "mdi-view-dashboard" },
      ],
      mini: true,
      
    };
  },
  methods: {
    
  },
  
  async created() {
  
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
